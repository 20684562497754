var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.wrapper,{tag:"component"},[_c('KFocusTrap',[_c('transition',{attrs:{"name":"modal-fade","appear":""}},[_c('div',{ref:"modal-overlay",staticClass:"modal-overlay",attrs:{"id":"modal-window"},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.stopPropagation();return _vm.emitCancelEvent.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEnter.apply(null, arguments)}]}},[_c('div',{ref:"modal",staticClass:"modal",style:(_vm.modalStyles),attrs:{"tabindex":0,"role":"dialog","aria-labelledby":"modal-title"}},[_c('h1',{ref:"title",staticClass:"title",attrs:{"id":"modal-title"}},[_vm._v("\n            "+_vm._s(_vm.title)+"\n            "),_vm._v(" "),(_vm.hasError)?_c('span',{staticClass:"visuallyhidden"},[_vm._v("\n              "+_vm._s(_vm.errorMessage)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.emitSubmitEvent.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();}}},[_c('div',{ref:"content",staticClass:"content",class:{
                'scroll-shadow': _vm.scrollShadow,
              },style:([
                _vm.contentSectionMaxHeight,
                _vm.scrollShadow
                  ? {
                    borderTop: `1px solid ${_vm.$themeTokens.fineLine}`,
                    borderBottom: `1px solid ${_vm.$themeTokens.fineLine}`,
                  }
                  : {},
              ])},[_vm._t("default")],2),_vm._v(" "),_c('div',{ref:"actions",staticClass:"actions"},[(_vm.$slots.actions)?_vm._t("actions"):[(_vm.cancelText)?_c('KButton',{attrs:{"name":"cancel","text":_vm.cancelText,"appearance":"flat-button","disabled":_vm.cancelDisabled || _vm.$attrs.disabled},on:{"click":_vm.emitCancelEvent}}):_vm._e(),_vm._v(" "),(_vm.submitText)?_c('KButton',{attrs:{"name":"submit","text":_vm.submitText,"primary":true,"disabled":_vm.submitDisabled || _vm.$attrs.disabled,"type":"submit"}}):_vm._e()]],2)])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }