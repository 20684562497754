<template>

  <DocsPageTemplate apiDocs>
    <DocsPageSection
      title="Overview"
      anchor="#overview"
    >
      Displays a tooltip when a user hovers over a specific element.
    </DocsPageSection>

    <DocsPageSection
      title="Usage"
      anchor="#usage"
    >
      <h3>Use of <code>ref</code> and <code>reference</code></h3>

      <KTooltip
        reference="myButton"
        :refs="$refs"
      >
        Click me to learn more!
      </KTooltip>
      <button ref="myButton">Learn More</button>

      <DocsShowCode language="html">
        &lt;KTooltip
          reference="myButton"
          :refs="$refs"
        &gt;
          Click me to learn more!
        &lt;/KTooltip&gt;
        &lt;button ref="myButton"&gt;Learn More&lt;/button&gt;
      </DocsShowCode>
      <p>
        The <code>ref</code> attribute creates a reference to the <code>myButton</code> element, and
        the <code>reference</code> prop associates the tooltip with this reference.
      </p>

      <h3>Disabled state</h3>

      <KTooltip
        reference="disableButton"
        :refs="$refs"
        disabled
      >
        This tooltip is disabled
      </KTooltip>
      <button ref="disableButton">Learn More</button>

      <DocsShowCode language="html">
        &lt;KTooltip
          reference="disableButton"
          :refs="$refs"
          disabled
        &gt;
          This tooltip is disabled
        &lt;/KTooltip&gt;
        &lt;button ref="disableButton"&gt;Learn More&lt;/button&gt;
      </DocsShowCode>
      <p>The <code>disabled</code> prop can be used to disable the tooltip.</p>

      <h3>Supported placements</h3>
      <div style="display: flex; flex-wrap: wrap">
        <div style="margin: 10px">
          <KTooltip
            reference="topButton"
            :refs="$refs"
            placement="top"
          >
            Top placement
          </KTooltip>
          <button ref="topButton">Top</button>
        </div>
      </div>
      <DocsShowCode language="html">
        &lt;KTooltip
          reference="topButton"
          :refs="$refs"
          placement="top"
        &gt;
          Top placement
        &lt;/KTooltip&gt;
        &lt;button ref="topButton"&gt;Top&lt;/button&gt;
      </DocsShowCode>
      <p>The <code>placement</code> prop can be used to specify the placement of the tooltip.</p>

      <h3>Maximum width</h3>
      <KTooltip
        reference="maxWidthButton"
        :refs="$refs"
        maxWidth="200px"
      >
        This tooltip has a max width of 200px
      </KTooltip>
      <button ref="maxWidthButton">Learn More</button>
      <DocsShowCode language="html">
        &lt;KTooltip
          reference="maxWidthButton"
          :refs="$refs"
          maxWidth="200px"
        &gt;
          This tooltip has a max width of 200px
        &lt;/KTooltip&gt;
        &lt;button ref="maxWidthButton"&gt;Learn More&lt;/button&gt;
      </DocsShowCode>
      <p>The <code>maxWidth</code> prop can be used to limit the tooltip width.</p>
    </DocsPageSection>
  </DocsPageTemplate>

</template>


<script>

  export default {};

</script>
